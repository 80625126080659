import { mapGetters } from "vuex"

export default {
  name: "dashboard",
  components: {},
  
  data() {
    return {
      dashboard: false,
    };
  },
  
  computed: {
    ...mapGetters(["layoutConfig"])
  },

  mounted() {
    //
  },
  methods: {
    //
  }
};